.about-us {
  display: flex;
  align-items: center;
  gap: 20px;
  position: absolute;
  top: 30px;
  left: 30px;
}

.mobile-about-us {
  @extend .about-us;
  top: 15px;
  left: 5px;
  gap: unset;
  flex-direction: column;
}

.svg-style {
  transform: scale(1);
}

.mobile-svg-style {
  transform: scale(0.75);
}