.header {
  margin-top: -55px;
  padding-bottom: 30px;
  text-align: center;

  .header-title {
    font-family: cursive;
    color: #1e1e1f;
    margin-top: -50px !important;
    font-size: 20px;
    margin: 0;
  }

  .mobile-header-title {
    @extend .header-title;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 19px;
    margin-top: -55px !important;
    @media (max-width: 410px) {
      font-size: 18.5px;
    }
    @media (max-width: 400px) {
      font-size: 18px;
    }
    @media (max-width: 390px) {
      font-size: 17.5px;
    }
    @media (max-width: 380px) {
      font-size: 17px;
    }
    @media (max-width: 370px) {
      font-size: 16.5px;
    }
    @media (max-width: 360px) {
      font-size: 16px;
    }
  }

  .heb-mobile-header-title {
    @extend .mobile-header-title;
    font-size: 18.3px;
    @media (max-width: 410px) {
      font-size: 17.8px;
    }
    @media (max-width: 400px) {
      font-size: 17.3px;
    }
    @media (max-width: 390px) {
      font-size: 16.8px;
    }
    @media (max-width: 380px) {
      font-size: 16.3px;
    }
    @media (max-width: 370px) {
      font-size: 15.8px;
    }
    @media (max-width: 360px) {
      font-size: 15.3px;
    }
  }
}  