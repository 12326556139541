.language-switcher {
  position: absolute;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  border: none;
  // border-radius: 50%;
  background: none;
  cursor: pointer;
  transform: scale(1.2);
  transition: transform 0.2s ease-in-out;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  //   box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  }

  &:hover {
    transform: scale(1.3);
  }
}

.mobile-language-switcher {
  @extend .language-switcher;
  top: 15px;
  right: 10px;
  transform: scale(1);
  &:hover {
    transform: scale(1.1);
  }
}
