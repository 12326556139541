.more-info {
    font-family: cursive;
    text-align: center;
    margin: 20px auto;
    max-width: 600px;
    color: #1e1e1f;
    font-size: 19px;
  }
  
  .hours div {
    padding: 5px 0;
    // border-bottom: 1px solid #ddd;
  }
  
  .hours span:last-child {
    border-bottom: none;
  }

.mobile-more-info {
  @extend .more-info;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  @media (max-width: 410px) {
    font-size: 18.5px;
  }
  @media (max-width: 400px) {
    font-size: 18px;
  }
  @media (max-width: 390px) {
    font-size: 17.5px;
  }
  @media (max-width: 380px) {
    font-size: 17px;
  }
  @media (max-width: 370px) {
    font-size: 16.5px;
  }
  @media (max-width: 360px) {
    font-size: 16px;
  }
}

.heb-mobile-more-info {
  @extend .mobile-more-info;
  font-size: 18.3px;
  @media (max-width: 410px) {
    font-size: 17.8px;
  }
  @media (max-width: 400px) {
    font-size: 17.3px;
  }
  @media (max-width: 390px) {
    font-size: 16.8px;
  }
  @media (max-width: 380px) {
    font-size: 16.3px;
  }
  @media (max-width: 370px) {
    font-size: 15.8px;
  }
  @media (max-width: 360px) {
    font-size: 15.3px;
  }
}
  