/* Menu.scss */
.menu-button {
    display: flex;
    background-color: #1e275c; /* Blue color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  
    &:hover {
      border-color: #0d202c;
    }
  }
  
  .menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(5px); /* Applies the blur effect */
  }
  
  .menu-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%; /* Prevents the image from becoming too large */
    max-height: 90%; /* Prevents the image from becoming too large */
  }
  
  .menu-image {
    object-fit: contain;
    transition: transform 0.3s ease; /* Smooth transition for zoom */
  }
  
  .zoom-controls {
    position: fixed;
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
  }
  
  .zoom-button {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
  
  .close-button {
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border: none;
    border-radius: 50%;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:hover {
      background-color: rgba(255, 0, 0, 1);
    }
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  