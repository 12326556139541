.phone-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    margin: 20px 0;
    width: 50%;
  }
  
  .phone-number {
    font-size: 24px;
    font-weight: bold;
    color: #0077b6;
    text-decoration: none;
    transition: color 0.3s ease;
  
    &:hover {
      color: #005f99;
    }
  }
  