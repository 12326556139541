.image-carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.image-container {
  display: flex;
  align-items: center;
  white-space: nowrap; /* Prevent images from wrapping */
}

.image {
  flex-shrink: 0;
  width: 220px;   /* Image width */
  height: 280px;  /* Image height */
  margin-right: 50px; /* Gap between images */
}

.mobile-image {
  flex-shrink: 0;
  width: 180px;   /* Image width */
  height: 220px;  /* Image height */
  margin-right: 50px; /* Gap between images */
}
