html::-webkit-scrollbar {
    width: 15px;
  }
   
  html::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #ffffff;
    box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  }
   
  html::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #228be6;
    border: 1px solid blue;
  }

.app {
  background: linear-gradient(rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)),url('/assets/back.jpg') no-repeat center center fixed;
  background-size: cover;
}