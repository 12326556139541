.map-container {
  height: 500px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  border: 2px solid #0074D9;
  border-radius: 10px;
  overflow: hidden;
}
.mobile-map-container {
  @extend .map-container;
  height: 350px;
  width: 350px;
}
iframe {
  border-radius: 10px;
}
.map-fallback {
  border-radius: 10px;
}

.address {
  font-family: cursive;
  font-size: 19px;
  margin-top: 20px;
  display: flex;
  color: #1e1e1f;
  justify-content: center;
}

.mobile-address {
  @extend .address;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}
  